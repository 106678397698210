@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* This is a fix for clerk org background color in org switcher */
.cl-avatarBox img {
  background-color: #fff;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #ededed;
}
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #a3a3a3;
}

/* This is a fix for toastify container width */
.Toastify__toast-container {
  width: fit-content !important;
}

.Toastify__toast {
  width: fit-content !important;
  max-width: 80%;
  margin: 0 auto;
}
